import React from 'react';
import type { PageProps } from 'gatsby';

import { AppState } from '../../../services/AppState';
import PageLayout from '../../../views/Layout/Page';
import MyVehiclesPage from '../../../views/Vehicle/MyVehiclesPage';
import MyVehicleTab from '../../../views/Vehicle/MyVehicleTabs';

export default function CarsDetailPage({ params }: PageProps) {
  return (
    <PageLayout pageview="my_cars_reservations" private requiredState={AppState.LOADED}>
      <MyVehiclesPage hash={params.hash} tab={MyVehicleTab.RESERVATION} />
    </PageLayout>
  );
}
